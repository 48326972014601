import { useState } from 'react';
import './App.css';
import Footer from './componant/Footer';
import Skill from './componant/Skill';
import Project from './componant/project';
import Home from './componant/Home';
import Navbar from './componant/Navbar';
import Info from './componant/Info'
import Error from './componant/Error'
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
// import Calculator from './componant/Calculator';

function App() {
  const [mode, setMode] = useState('')

  const toggle = () => {
    if (document.body.style.backgroundColor === "rgb(9, 9, 92)") {
      document.body.style.backgroundColor = "rgb(221, 244, 255)"
      document.body.style.color = "black"
      setMode('rgb(221, 244, 255)')
    }
    else {
      document.body.style.backgroundColor = "rgb(9, 9, 92)"
      document.body.style.color = "white"
      setMode('rgb(9, 9, 92)')
    }
  }
  return (
    <>
      <Router>
        <Navbar stch={toggle} />
        <Routes>
          <Route exact path="/" element={<Home homestch={mode} />} />
          <Route exact path="/skill" element={<Skill homestch={mode} />} />
          <Route exact path="/project" element={<Project homestch={mode} />} />
          <Route exact path="/Info" element={<Info homestch={mode} />} />
          <Route path="/*" element={<Error homestch={mode}/>} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
