import React from 'react'
import { Link } from 'react-router-dom'
import mport from './portfolio.png'
import dbord from './dashbord.png'
import vfom from './validform.png'

export default function project(props) {
  return (
    <div className='project-container'  style={{backgroundColor: props.homestch === "rgb(9, 9, 92)"? "rgb(9, 9, 92)": "rgb(221, 244, 255)"}}>
      {/* this link from shoelace */}
      <div className='all-project card'>
        <div className='row-1'>


          <div className='card-project' style={{backgroundColor: props.homestch === "rgb(9, 9, 92)"? "rgb(3, 3, 35)": "rgb(93, 204, 255)"}}>
            <img alt='' src={mport}/>
            <p>complete portfolio project using html and css.</p>
            <Link to='https://anuhang9.github.io/portfolio-template/' className='cp-link' target='_blank'>visit</Link>
          </div>
          <div className='card-project' style={{backgroundColor: props.homestch === "rgb(9, 9, 92)"? "rgb(3, 3, 35)": "rgb(93, 204, 255)"}}>
            <img alt='' src={dbord}/>
            <p>complete account project using html and css.</p>
            <Link to='https://anuhang9.github.io/profile-dashboard/' className='cp-link' target='_blank'>visit</Link>
          </div>
          <div className='card-project' style={{backgroundColor: props.homestch === "rgb(9, 9, 92)"? "rgb(3, 3, 35)": "rgb(93, 204, 255)"}}>
            <img alt='' src={vfom}/>
            <p>valid form project using html css and javascript.</p>
            <Link to='https://anuhang9.github.io/valid_form/' className='cp-link' target='_blank'>visit</Link>
          </div>
          
          <div className='card-project' style={{backgroundColor: props.homestch === "rgb(9, 9, 92)"? "rgb(3, 3, 35)": "rgb(93, 204, 255)"}}>
            <img alt='' src=''/>
            <p>comming soon . . .</p>
            <Link to='#' className='cp-link' target='_blank'>visit</Link>
          </div>
          


        </div>
      </div>
    </div>
  )
}
