import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

export default function Navbar(props) {
  return (
    <header className='main-nav'>
      <nav>
        <h4>Anuhang's Portfolio</h4>
        <ul>
          <li><Link to='/'>Home</Link></li>
          <li><Link to='/skill'>Skill</Link></li>
          <li><Link to='/project'>Project</Link></li>
          <li><Link to='/Info'><FontAwesomeIcon icon={faInfo} beat /></Link></li>
          <div className='myclass'>
            <input type='checkbox' id='switch' name='switch' value='switch' onClick={props.stch}/>
            <label htmlFor='switch' className='label'></label>
          </div>
        </ul>
      </nav>
    </header>
  )
}
